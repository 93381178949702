<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="apply-audit-dialog">
  <div class="dialog-body" v-loading="loading">
    <div class="aad-title">申请信息</div>
    <table class="aad-base-info-table my-table">
      <tr>
        <th>所属项目</th>
        <td>{{compensationDetails.txt_project_name}}</td>
        <th>所属中心</th>
        <td>{{compensationDetails.txt_hospital_name}}</td>
      </tr>
      <tr>
        <th>所属研究者</th>
        <td>{{compensationDetails.txt_researcher_name}}</td>
        <th>访视阶段</th>
        <td>{{compensationDetails.txt_interview_name}}</td>
      </tr>
      <tr>
        <th>费用金额</th>
        <td>{{compensationDetails.dec_compensation_total}}元</td>
        <th>申请时间</th>
        <td>{{compensationDetails.dt_researcher_verify||"- -"}}</td>
      </tr>
    </table>
    <div class="aad-title has-margin-top">受试者信息</div>
    <table class="aad-base-info-table my-table">
      <tr>
        <th>筛选号</th>
        <td>{{compensationDetails.txt_filter_number}}</td>
        <th>姓名缩写</th>
        <td>{{compensationDetails.txt_examinee_spell}}</td>
      </tr>
      <tr>
        <th>性别</th>
        <td>{{compensationDetails.txt_examinee_gender}}</td>
        <th>年龄</th>
        <td>{{compensationDetails.int_examinee_age}}</td>
      </tr>
    </table>
    <div class="aad-title has-margin-top">费用明细</div>
    <div class="cost-detail-list-table">
      <div class="cdlt-item" v-for="(item, index) in expenseDetails" :key="index">
        <div class="cdlt-item-line">
          <div class="cdltil-title">费用类型</div>
          <div class="cdltil-content">{{item.txt_compensation_type_name}}</div>
        </div>
        <div class="cdlt-item-line">
          <div class="cdltil-title">申报金额</div>
          <div class="cdltil-content">{{item.dec_compensation_amount}}元</div>
        </div>
        <div class="cdlt-item-line">
          <div class="cdltil-title">申报明细</div>
          <div class="cdltil-content">{{item.txt_compensation_item_desc}}</div>
        </div>
        <div class="cdlt-img-line" v-if="(item.imgList||[]).length>0">
          <div class="cdltil-title">凭证</div>
          <!-- <div class="cdltil-content-imgs">
            <el-image v-for="(sItem, sIndex) in item.imgList" :key="sIndex" :src="sItem" :preview-src-list="item.imgList" fit="cover" />
          </div> -->
          <div class="cdltil-content-imgs">
            <div class="cdltilci-item" v-for="(sItem, sIndex) in item.imgList" :key="sIndex">
              <template v-if="judgeFormat(sItem)">
                <el-image :src="sItem" :preview-src-list="viewImg(item.imgList)" fit="cover" />
              </template>
              <template v-else>
                <div class="file-part" @click="viewFile(sItem)">
                  <el-icon>
                    <Document />
                  </el-icon>
                  {{ getFileName(sItem) }}
                </div>
              </template>
            </div>
            <!-- <el-image :src="sItem" :preview-src-list="item.imgList" fit="cover" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <template v-if="dialogData.type !='detail'" #footer>
    <div class="dialog-footer">
      <el-button color="#8a88c3" @click="audit(true)" type="primary">同 意</el-button>
      <el-button @click="audit(false)" type="info">拒 绝</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 审核组件
// "status": 1, 状态 1 同意 2 拒绝
import { onMounted, reactive, toRefs } from "vue";
import { Document } from "@element-plus/icons-vue";
import { getCostDetail, getCRAVerify } from "api/apis.js";
import { ElMessage, ElMessageBox } from 'element-plus/lib/components';
export default {
  props: {
    dialogData: { type: Object },
  },
  components: {
    Document,
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      picsData: [],
      loading: false,
      txt_approval_number: null,
      compensationDetails: {},
      expenseDetails: [],
      title: "审核",
      dialogData: {}
    })

    onMounted(() => {
      state.dialogData = prop.dialogData;
      if (prop.dialogData.type == "detail") {
        state.title = "详情" + (prop.dialogData.txt_cra_verify_status == 1 ? " - 已同意" : " - 已拒绝");
      }
      getAuditDetail();
    })

    // 格式化文件名
    const getFileName = iItem => {
      let fileName = iItem.split("?")[0];
      return fileName.split(".com/")[1];
    };

    // 判断文件类型 图片/文件
    const judgeFormat = sItem => {
      let judge = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"],
        endWith = sItem.split("?")[0].split("com/")[1].split(".")[1];
      return judge.findIndex(t => t == endWith) != -1;
    };

    // 预览图片
    const viewImg = imgList => {
      let urls = [],
        judge = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"];
      for (let i = 0; i < imgList.length; i++) {
        let endWith = imgList[i].split("?")[0].split("com/")[1].split(".")[1];
        if (judge.findIndex(t => t == endWith) != -1) {
          urls.push(imgList[i]);
        }
      }
      return urls;
    };

    // 预览文件
    const viewFile = sItem => {
      window.open(sItem);
    };

    // 获取详情
    const getAuditDetail = () => {
      state.loading = true;
      getCostDetail({
        t_compensation_application_id: prop.dialogData.application_id
      }).then(response => {
        if (response.code == 200) {
          state.compensationDetails = response.data.compensationDetails;
          state.expenseDetails = response.data.expenseDetails;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 审核
    const audit = param => {
      let verifyRemarks = "";
      if (param == false) {
        ElMessageBox.prompt('请简述拒绝理由', '拒绝申请', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          verifyRemarks = value;
          goSubmit(verifyRemarks, param);
        }).catch(() => {})
      } else {
        goSubmit(verifyRemarks, param);
      }
    };

    // 调用审核提交接口
    const goSubmit = (param, type) => {
      state.loading = true;
      getCRAVerify({
        t_verify_cra_id: prop.dialogData.t_verify_cra_id,
        txt_cra_verify_remarks: param || "",
        status: type ? 1 : 2,
        t_compensation_application_id: prop.dialogData.application_id
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("审核成功");
          close(1);
        } else {
          ElMessage.error("审核失败" + response.msg);
          close(1);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = (type) => {
      ctx.emit("close", type);
    }

    return {
      ...toRefs(state),
      close,
      audit,
      getAuditDetail,
      goSubmit,
      judgeFormat,
      getFileName,
      viewImg,
      viewFile
    }
  }
}
</script>

<style lang="scss">
.apply-audit-dialog {
  width: 650px;
  height: calc(100% - 25vh) !important;

  .el-dialog .el-dialog__body {
    height: calc(100% - 115px);
    padding: 20px 20px;
  }

  .aad-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
    margin-top: 0;

    &.has-margin-top {
      margin-top: 10px;
    }
  }

  .aad-base-info-table {
    .pics-part-title {
      height: 50px;
      font-size: 18px;
    }

    .tr-has-image {
      .el-image {
        height: 200px;
      }
    }
  }

  .dialog-footer {
    .el-button--primary {
      color: #fff;
    }
  }
}
</style>
