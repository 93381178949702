<template>
<el-breadcrumb class="layout-breadcrumb" spellcheck="/">
  <el-breadcrumb-item v-for="(item, index) in breadData" :key="index">
    {{item.title}}
  </el-breadcrumb-item>
</el-breadcrumb>
</template>

<script>
// 面包屑
import { reactive, toRefs, watch } from 'vue';
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      breadData: [],
    });

    // 监听路由
    watch(() => router.currentRoute.value, (newValue) => {
      if (newValue.path != "/login") {
        if (newValue.path != "/") {
          // 父菜单数组
          let breadHeader = [
              { key: "cost", value: "访视费用申请" },
            ],
            // 寻找当前路由的父菜单
            targetIndex = breadHeader.findIndex(t => t.key == newValue.meta.father);
          // 定义面包屑样式
          state.breadData = [
            { title: breadHeader[targetIndex].value },
            { title: newValue.meta.title }
          ];
        }
      }
    }, { immediate: true })

    return toRefs(state);
  }
}
</script>

<style lang="scss">
.layout-breadcrumb {
  font-size: 16px;

  .el-breadcrumb__item {
    &:first-child {
      .el-breadcrumb__inner {
        font-weight: bold;
        font-size: 16px;
        color: #8a88c3;
      }
    }
  }
}
</style>
