<template>
<div class="layout-nav-bar row-center-between">
  <div class="lnb-left row-center-between">
    <breadcrumb ref="breadRef" />
  </div>
  <div class="lnb-right row-center-between">
    <div class="lnbr-username">{{username}}</div>
    <el-popover trigger="hover" placement="bottom" :width="80">
      <div style="margin: 0;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <el-button size="large" text @click="dialogShow=true" >修改密码</el-button>
        <el-button size="large" style="margin: 0;" text @click="logOut">退出登录</el-button >
      </div>
      <template #reference>
        <i class="iconfont icon-gongju"/>
      </template>
    </el-popover>
  </div>
  <change-psw v-if="dialogShow" @close="dialogShow=false" />
</div>
</template>

<script>
// 全局 - 导航面包屑
import Breadcrumb from "../Breadcrumb";
import { ChangePsw } from "components";
import { computed, reactive, ref, toRefs } from 'vue';
export default {
  components: {
    Breadcrumb,
    ChangePsw
  },
  setup() {
    const breadRef = ref(null);
    const state = reactive({
      username: computed(() => {
        // 判断当前登录用户 - 区分开发环境
        if (process.env.NODE_ENV == "development") {
          return "系统用户";
        } else {
          let craInfo = JSON.parse(sessionStorage.getItem("craInfo"));
          return craInfo.txt_cra_name;
        }
      }),
      dialogShow: false
    });

    // 退出登录
    const logOut = () => {
      sessionStorage.removeItem("craInfo")
      location.reload();
    }

    return {
      ...toRefs(state),
      logOut,
      breadRef,
    };
  }
}
</script>

<style lang="scss" scoped>
.layout-nav-bar {
  height: 50px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .lnb-left {
    height: 100%;
    margin-left: 20px;

    .lnbl-icon {
      font-size: 26px;
      padding: 0 15px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      -webkit-transition: width 0.28s;
      transition: width 0.28s;

      &:hover {
        background-color: #f9f9f9;
      }
    }

    .lnbl-btn {
      margin-left: 6px;
    }
  }

  .lnb-right {
    height: 100%;
    margin-right: 30px;

    .lnbr-username {
      color: #8a88c3;
    }

    i {
      cursor: pointer;
      font-size: 20px;
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;
      color: #8a88c3;

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}
</style>
