<template>
<div class="sidebar-logo-part">
  <div class="sidebar-title column-center-center">
    <span>{{settings.systemTitle}}</span>
    <span>Lidu Medical Technology Co.,Ltd.</span>
  </div>
</div>
</template>

<script>
// 左侧菜单部分 - 顶部logo
import { computed, reactive, toRefs } from "vue";
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      settings: computed(() => store.state.settings)
    });

    return toRefs(state);
  }
}
</script>

<style lang="scss" scoped>
.sidebar-logo-part {
  padding: 20px 0;

  .sidebar-only-logo {
    height: 50px;
  }

  .sidebar-title {
    height: 50px;

    span {
      -webkit-transition: width 0.28s;
      transition: width 0.28s;
      font-weight: bold;
      font-size: 14px;

      &:first-child {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
