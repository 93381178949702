import { createStore } from 'vuex';
import settings from "./modules/settings";
import getters from "./getters";


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    settings,
  },
  getters
})
