const state = {
  systemTitle: "药研有度运营管理平台",
  menuData: [
    {
      id: "1", title: "访视费用申请", show: true, icon: "iconfont icon-caiwuguanli", children: [
        { id: "1-0", title: "待处理申请", icon: "", path: "/pending", children: [] },
        { id: "1-1", title: "已处理申请", icon: "", path: "/processed", children: [] },
      ]
    }
  ]
}

export default {
  state
};