import request from './request';

// CRA 登录
export function getLogin(data) {
  return request({
    url: "/login",
    method: "post",
    data
  })
}

// CRA 修改密码
export function getChangPwd(data) {
  return request({
    url: "/changePwd",
    method: "post",
    data
  })
}

// 根据登录人ID 查询项目列表 select
export function getProjectByUserId(data) {
  return request({
    url: "/getCRAProjectList",
    method: "post",
    data
  })
}

// 获取访视费用申请列表
export function getCostList(data) {
  return request({
    url: "/getCompensationList",
    method: "post",
    data
  })
}

// 获取访视费用申请明细
export function getCostDetail(data) {
  return request({
    url: "/getCompensationDetails",
    method: "post",
    data
  })
}

// 访视费用申请  审核
export function getCRAVerify(data) {
  return request({
    url: "/craVerify",
    method: "post",
    data
  })
}

